<template>
  <ui-component-modal
    :showModal="showModal"
    :hideFooterButtons="true"
    :modalSize="modalSize"
    :onClickCancel="onClickCancel"
  >
    <template v-slot:content>
      <img :src="image" alt="" title="" />
    </template>
  </ui-component-modal>
</template>

<script>
export default {
  name: 'modal-image-preview',
  props: {
    modalSize: {
      type: String,
      default: 'large',
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    image: {
      type: String,
      default: '',
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
