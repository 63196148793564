<template>
  <ui-component-modal
    modalTitle="Delete photo"
    :buttonLabelConfirm="'Delete'"
    confirmButtonCssClass="is-danger"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteImage"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div>
        <div class="has-margin-bottom is-size-5 has-text-centered">
          Are you sure you want to delete this image?
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            :label="'Yes, I am sure!'"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    image: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
      mLocation: null,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {
    this.mLocation = JSON.parse(JSON.stringify(this.location))
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),

    deleteImage() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        locationProvider.methods
          .deleteLocationImage(self.image.Id)
          .then((response) => {
            if (response.status === 204) {
              self.isDeletingSuccess = true

              let index = self.mLocation.Images.findIndex(
                (i) => i.Id === self.image.Id
              )
              if (index > -1) {
                self.mLocation.Images.splice(index, 1)
              }

              self.setLocation(self.mLocation)

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((e) => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
